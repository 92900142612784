import { createSelector } from '@ngrx/store';
import * as fromLogin from '../reducers/login.reducer';
import { selectAuthState } from './index';
import {LoginState, initialState} from '../reducers/login.reducer';

export const selectLoginState = createSelector(selectAuthState, state => {
  return state ? state[fromLogin.loginFeatureKey] : initialState;
});

export const selectLoginLoading = createSelector(selectLoginState, fromLogin.getLoading);

export const selectLoginError = createSelector(selectLoginState, fromLogin.getError); 
