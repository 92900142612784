import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
  lang: string;
  data: Object;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationLoaderService {
  constructor(private translate: TranslateService) {}

  /**
   * Loads the given locales and appends their translations to the existing translations.
   *
   * @param {...Locale[]} args - The locales to load and their corresponding translations.
   * @return {void} This function does not return anything.
   */
  public loadTranslations(...args: Locale[]): void {
    const locales = [...args];
    locales.forEach(locale => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);
    });
    // this.translate.setDefaultLang("");
    // this.translate.use();
  }

  /**
   * Sets the default language and uses the specified locale.
   *
   * @param {string} [locale='fr'] - The locale to set as the default language.
   * @return {void} This function does not return anything.
   */
  setDefaultLanguage(locale: string = 'fr'): void {
    this.translate.setDefaultLang(locale);
    this.translate.use(locale);
  }

  /**
   * Returns the instance of the TranslateService.
   *
   * @return {TranslateService} The instance of the TranslateService.
   */
  getTranslateInstance() {
    return this.translate;
  }
}
