import { Injectable, inject } from '@angular/core';
import { HttpService } from '@src/app/core/services';
import { USER_REFRESH_TOKEN_BASE_URL } from '../constants';
import {GET_USER_DETAILS, LOGOUT, GET_DEFAULTS_BASE_URL, AUTH_REFRESH_TOKEN_BASE_URL} from '@src/app/core/constants/apis-list.constant';
import {LocalStorageService} from '@src/app/core/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  private localStorageService = inject(LocalStorageService);
  private httpService = inject(HttpService);

  resetToken() {
    return this.httpService.requestEntity('POST', AUTH_REFRESH_TOKEN_BASE_URL, {refreshToken:this.localStorageService.getToken(true)});
  }
  resetTokenUser() {
    return this.httpService.requestEntity('POST', USER_REFRESH_TOKEN_BASE_URL, {});
  }
  getUserDetails() {
    return this.httpService.requestEntity('GET', GET_USER_DETAILS);
  }
  getBaseURL() {
    return this.httpService.requestEntity('GET', GET_DEFAULTS_BASE_URL);
  }
  logout() {
    return this.httpService.requestEntity('GET', LOGOUT);
  }
}
