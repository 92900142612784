import { BaseModel } from '@app/core/models/base.model';

export class User extends BaseModel {
  public defaultLanguage: string | null = null;
  public email: string | null = null;
  public employeeId: string | null = null;
  public firstName: string | null = null;
  public lastName: string | null = null;
  public expiry_date?: string | null = null;

  /**
   * Initializes a new instance of the User class.
   *
   * @param {any} values - Optional values to initialize the User object.
   */
  constructor(values?: any) {
    super();
    this.active = false;
    if (values && Object.keys(values)?.length) {
      Object.assign(this, values);
    }
  }
}
