/**
 * Returns the trimmed value of a string, or the default value if the input value is null or empty.
 *
 * @param {string | null} value - The string value to be trimmed.
 * @param {string | null} [defaultValue=null] - The default value to be returned if the input value is null or empty.
 * @return {string | null} The trimmed value of the input string, or the default value if the input value is null or empty.
 */
export function getTrimValue(
  value: string | null,
  defaultValue: string | null = null,
): string | null {
  return value?.trim() || defaultValue;
}
