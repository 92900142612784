import { getTrimValue } from './get-trim-value';

/**
 * Trims the given value and sets it to the default value if it is null or empty.
 *
 * @param {string | null} value - The value to be trimmed.
 * @param {string | null} [defaultValue=null] - The default value to be set if the trimmed value is null or empty.
 * @return {void} This function does not return a value.
 */
export function trimValue(value: string | null, defaultValue: string | null = null) {
  value = getTrimValue(value, defaultValue);
}
