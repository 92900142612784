import { createReducer, on } from '@ngrx/store';
import { AuthActions, LoginActions } from '../actions';
import { User } from '@app/core/api';

export const loginFeatureKey = 'login';

export interface LoginState {
  loading: boolean;
  error: string | null;
  user: User | null;
  accessToken: string | null;
}

export const initialState: LoginState = {
  loading: false,
  error: null,
  user: null,
  accessToken: null,
};

export const loginReducer = createReducer(
  initialState,
  on(
    LoginActions.login,
    (state): LoginState => ({ ...state, loading: true, error: null }),
  ),
  on(
    AuthActions.loginSuccess,
    (state): LoginState => ({
      ...state,
      loading: false,
      error: null,
      user: null,
      accessToken: null,
    }),
  ),
  on(
    AuthActions.loginFailure,
    (state, { error }): LoginState => ({
      ...state,
      error,
      loading: false,
    }),
  ),
);

export const getLoading = (state: LoginState) => state.loading;

export const getError = (state: LoginState) => state.error;
