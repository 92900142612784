import * as fromUser from './user.reducer';
import * as fromLogin from './login.reducer';
import * as fromRoot from '@src/app/core/store';

import { Action, combineReducers } from '@ngrx/store';

export const authFeatureKey = 'auth';

export interface AuthState {
  [fromUser.userFeatureKey]: fromUser.State;
  [fromLogin.loginFeatureKey]: fromLogin.LoginState;
}

export interface State extends fromRoot.State {
  [authFeatureKey]: AuthState;
}

export const authReducers = (state: AuthState | undefined, action: Action) =>
  combineReducers({
    [fromUser.userFeatureKey]: fromUser.reducer,
    [fromLogin.loginFeatureKey]: fromLogin.loginReducer,
  })(state, action);
